<template>
  <div class="height-100" style="background-color: #F8F8F8;">
    <!-- <div class="t-a-c bgc-white head" style="font-size: 1.1rem; font-weight: 600;color: black;">分类</div> -->
    <div class="fist">
      <!-- <div class="left-nav " ref="leftscoll">
        <van-sidebar v-model="activeKey" @change="onChange">
          <van-sidebar-item v-for="(item, index) in category" :key="index" :title="item.name" />
        </van-sidebar>
      </div> -->
      <div class="class-tabs" v-if="dataList">
        <div class="headtitle"> {{ active }}</div>
        <!-- <van-card class="cardsty" :price="item1.present_price" v-for="(item1, index1) in dataList" :key="index1"
            :origin-price="item1.orl_price" @click="goodOne(item1.id)" /> -->
        <div style="background-color:white; border-radius: 0.6rem; padding-bottom: 1rem; ">
          <div v-for="(item1, index1) in tempList" :key="index1" class="goodline">
            <div style="display: inline; " v-for="(item2, index2) in item1" :key="index2" class="cardsty"
              @click="goodOne(index1, index2)">
              <div>
                <van-image lazy-load fit="cover" class="goodImg" :src="item2.icon"
                    />
              </div>
              <div>
                <div class="goodtitle">{{ item2.name }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import FootNav from "../../components/footnav/FootNav.vue";
export default {
  name: "",
  props: {},
  data() {
    return {
      // 一级标题
      activeKey: 0,
      // 二级标题
      active: null,
      category: [

      ],
      level2: [

      ],
      tempList: [],
      //页面商品数据
      dataList: [],
      indexName: null,
      entorcode: '',
      cityCode: '',
      upgradeFlag: '',
      bathId: '',
    };
  },
  components: { FootNav },
  methods: {
    // 分类一级名字
    getData() {

      this.$api
        .classifyNew(this.bathId, this.upgradeFlag, this.cityCode)
        .then((res) => {
          if (res.code === 0) {
            this.category = res.data
            let selectIndex = 0
            if (this.indexName) {
              this.category.forEach((element, index) => {
                if (this.indexName == element.name) {
                  selectIndex = index
                }
              });
            }

            // this.activeKey = selectIndex
            // this.active = this.category[selectIndex].name
            // this.onClick(this.category[selectIndex].id)
            this.delayMethod(selectIndex)
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    delayMethod(index) {
      setTimeout(() => {
        this.onChange(index)
      }, 200);
    },


    // 分类页面数据
    onClick(val) {
      this.$api
        .secondClassifyNew(val, this.bathId, this.upgradeFlag, this.cityCode)
        .then((res) => {
          if (res.code === 0) {
            this.dataList = res.data;
            this.tempList = this.listTemp();
          }
        })
        .catch((err) => {
          console.log(err);
        });


    },
    // 一级导航切换事件 默认商品显示页面
    onChange(index) {
      this.activeKey = index;
      this.active = this.category[index].name;

      // let a = this.level2[this.activeKey][0].mallSubId;3
      //侧边栏选中 设置滚动位置
      // if(index>2){
      //   this.$refs.leftscoll.scrollTop= (index-3)*this.$refs.leftscoll.children[0].children[0].clientHeight
      // this.scollAnima(index)
      // }
      this.onClick(this.category[index].id);
    },

    scollAnima(index) {
      let _this = this
      let num = 1
      const oldTop = this.$refs.leftscoll.scrollTop;
      let speed = ((index - 3) * this.$refs.leftscoll.children[0].children[0].clientHeight - oldTop) / 5;
      if (index > 2) {
        let timer = setInterval(() => {
          _this.$refs.leftscoll.scrollTop = oldTop + speed * num
          num = num + 1;

          if (num == 6) {
            clearInterval(timer)
          }

        }, 30)
      }


    },
    //  查看详情
    goodOne(index1, index2) {
      let index = 3 * (index1) + index2
      // //  传递参数
      this.$router.push({
        path: "/GoodsList",
        query: {
          index1: this.activeKey,
          index2: index,
        },
      });
    },
    listTemp() {
      let index = 0
      const count = 3
      const arrTemp = []
      const experts = this.dataList
      for (let i = 0; i < this.dataList.length; i++) {
        index = parseInt(i / count)
        if (arrTemp.length <= index) {
          arrTemp.push([])
        }
        arrTemp[index].push(experts[i])
      }
      return arrTemp
    }
  },
  mounted() {
    this.entorcode = localStorage.getItem('entrCode')
    this.activeKey = this.$route.query.activeKey ? parseInt(this.$route.query.activeKey) : 0
    this.indexName = this.$route.query.indexName ? this.$route.query.indexName : null
    this.cityCode = localStorage.getItem('cityCode')
    this.upgradeFlag = localStorage.getItem("upgradeFlag");
    this.bathId = localStorage.getItem("bathId");
    this.getData();
    this.listTemp();
    // 进入页面显示在顶部
    window.scroll(0, 0);

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.fist {
  background-color: #F8F8F8;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100vh;
  margin-bottom: 4rem;
}

.red {
  color: #D33B2E;
}

.cardsty {
  width: 35%;
  text-align: center;
  overflow: hidden;
  margin-top: 1.2rem;

}

.goodImg {
  height: 6rem;
  width: 5rem;
}

.headtitle {
  width: 100%;
  background-color: #F8F8F8;
  color: black;
  padding-top: 1rem;
  font-size: 1rem;
  padding-bottom: 1rem;
}

.goodtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  font-size: 0.9rem;
  margin-right: 10px;
}

.goodline {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.class-tabs {
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: scroll;
  /* margin-top: 3rem; */


}

.left-nav {
  overflow: scroll;
  /* margin-top: 3rem; */
  /* border-right: 1px solid rgb(226, 223, 223); */
}

.head {
  position: fixed;
  width: 100%;
  height: 2rem;
  z-index: 999;
  overflow: hidden;
  padding: 0.5rem;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}



.van-sidebar {
  width: 6.5rem;

  .van-sidebar-item {
    background-color: white;
    font-size: 1rem;
    width: 6.5rem;
    line-height: 1.2rem;
    text-align: center;
  }

  .van-sidebar-item--select::before {
    position: absolute;
    background-color: #D33B2E;
    height: 2.5rem;
    right: inherit;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .van-sidebar-item--select,
  .van-sidebar-item--select:active {
    background-color: #F8F8F8;

    color: #D33B2E;

  }
}
</style>